<template>
  <list-container-widget
    id="articles"
    :title="widgetName"
    :app-id="104"
    stretch
    class="d-flex"
  >
    <template #actions>
      <ViewAllButton item-type="articles" />
    </template>
    
    <b-card-body>
      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items.length > 0">
        <div ref="container" class="x-scroll-container">
          <!-- Items Loop -->
          <div
            v-for="item in items"
            :key="item.key"
            class="x-scroll-element mx-1 pb-5 p-0 "
          >
            <b-link v-if="!item.parentKey" :to="{ name: 'article', params: { id: item.key } }"  class="link">
              <b-card class="card-article-widg p-0 card-article-container">
                <b-link :to="{ name: 'article', params: { id: item.key } }" class="link">
                  <h4 v-if="item.name" class="html-text-ellipsis-2">
                    {{
                      item.name[currentLocale] || Object.values(item.name)[0]
                    }}
                  </h4>
                  <b-media-body v-if="item.ownedByMember" class="mb-1">
                    <small class="text-muted mr-50">{{
                      $t("articles.created-by")
                    }}</small>
                    <small>
                      <b-link class="text-body">
                        {{ item.ownedByMember.name }}
                      </b-link>
                    </small>
                    <span class="text-muted ml-75 mr-50">|</span>
                    <small class="text-muted"
                      >{{ $t("articles.updated-at") }}
                      {{ timestampToTime(item.updatedAt) }}</small
                    >
                  </b-media-body>
                  <b-card-text>
                    <p
                      v-if="item.body"
                      class="html-text-ellipsis-5 mt-1 article-container"
                      v-html="
                        item.body[currentLocale] || Object.values(item.body)[0]
                      "
                    />
                    <p v-else class="min-height-3" />
                  </b-card-text>
                </b-link>
              </b-card>
            </b-link>
          </div>
          <!-- Buttons prev and next -->
          <button
            class="link-previous btn btn-icon btn-primary btn-previous"
            @click="scrollOnePage(-1)"
          >
            <b-spinner small class="spinner" />
          </button>
          <button
            class="link-next btn btn-icon btn-primary btn-next"
            :class="{ 'btn-next--loading': isLoadingNextPage }"
            @click="scrollOnePage(+1)"
          >
            <b-spinner small class="spinner" />
          </button>
        </div>
      </div>
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="blogPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: widgetName }) }}
          </p>
        </b-col>
      </b-row>
    </b-card-body>
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import BlogPlaceholder from "@/assets/images/placeholders/light/blog.svg";
// import { getImageResource } from "@/@core/utils/image-utils";

import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
// import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
// import { quillEditor } from "vue-quill-editor";
import moment from "moment";
import Placeholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
// import vSelect from "vue-select";
// import { checkPermissions } from "@/@core/utils/roles-utils";

export default {
  name: "ArticleListWidget",
  components: {
    ListContainerWidget,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      formInput: {},
      itemImage: null,
      imageSrc: null,
      searchInput: "",
      results: [],
      Placeholder,

      // Filters data
      areFiltersVisible: false,
      nextFilter: null,
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      selectedCustomFieldsForFilter: null,
      customFieldToSend: {},
      showfilter: false,
    };
  },
  computed: {
    // Filtros computed
    classifiers() {
      if (this.$store.getters.classifiers.articles) {
        return this.$store.getters.classifiers.articles.unpaginated;
      }
      return [];
    },
    sortedCommunityClassifiers() {
      const newObject = {};
      for (const item of this.classifiers) {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ].push(item);
        }
      }
      const keys = Object.keys(newObject);
      const finalKeys = [];
      for (const row of keys) {
        finalKeys.push({
          key: row,
          name: row,
          type: 1,
        });
      }
      return [newObject, finalKeys];
    },
    finalOptions() {
      return [].concat(
        this.sortedCommunityClassifiers[1],
        this.customFieldsOptions
      );
    },
    // Final filtros computed

    // Load data from store
    itemsData() {
      return this.$store.getters.articles.articles;
    },
    items() {
      // return this.itemsData.unpaginated;
      if (
        !this.itemsData ||
        this.itemsData.length === 0 ||
        !this.itemsData.unpaginated
      ) {
        return [];
      }
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData.unpaginated;
      return itemsToIterate;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    blogPlaceholder() {
      return BlogPlaceholder;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    // canAdd() {
    //   return checkPermissions(
    //     "create",
    //     "article",
    //     this.loggedMemberRoles,
    //     this.collective
    //   );
    // },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    // resetInputs() {
    //   this.formInput = {};
    //   this.itemImage = null;
    //   this.imageSrc = null;
    // },
    // canFilter() {
    //   if (this.classifiers.length > 0) {
    //     this.showfilter = true;
    //   } else {
    //     this.showfilter = false;
    //   }
    // },
    // Filtros methods
    // toggleFilters() {
    //   this.areFiltersVisible = !this.areFiltersVisible;
    //   if (!this.areFiltersVisible) {
    //     this.customFieldToSend = {};
    //     this.finalSelectClassifier = [];
    //     (this.selectedCustomFieldsForFilter = null), (this.nextFilter = null);
    //     (this.total = 0),
    //       (this.filtersIndex = 0),
    //       (this.filters = []),
    //       (this.parentClassifiers = []),
    //       (this.selectedClassifiersForFilter = []);
    //     this.customFieldsInputs = [];
    //     this.index = 0;
    //     this.fetchData(true);
    //   }
    // },
    handleChanger() {
      this.fetchData(true);
    },
    // async setSeletedFinalFilter(selected) {
    //   if (selected.type === 2) {
    //     this.customFieldToSend[selected.key] = selected.text;
    //   } else if (selected) {
    //     this.finalSelectClassifier = selected.key;
    //   } else {
    //     this.finalSelectClassifier = [];
    //   }
    //   this.handleChanger();
    // },
    // async fetchClassifiers() {
    //   this.isLoading = true;
    //   await this.$store.dispatch("getItems", {
    //     itemType: "classifiers",
    //     storedKey: "articles",
    //     page: 1,
    //     requestConfig: {
    //       morphType: "articles",
    //       count: 1000,
    //     },
    //   });
    //   this.canFilter();
    // },
    // async setSeletedFilter(selected) {
    //   this.CustomFieldToSend = null;
    //   this.finalSelectClassifier = null;
    //   this.selectedCustomFieldsForFilter = null;
    //   this.nextFilter = null;
    //   if (selected.length === 0) {
    //     this.finalSelectClassifier = null;
    //   } else {
    //     let translated = [];
    //     if (selected.type === 1) {
    //       for (const row of this.sortedCommunityClassifiers[0][selected.key]) {
    //         translated.push({
    //           name: translateTranslationTable(
    //             this.$store.state.locale.currentLocale,
    //             row.name
    //           ),
    //           key: row.key,
    //           type: 1,
    //         });
    //       }
    //     } else if (selected.type === 2) {
    //       this.customFieldToSend[selected.key] = "";
    //       translated = {
    //         type: 2,
    //         key: selected.key,
    //       };
    //     }
    //     this.nextFilter = translated;
    //   }
    // },
    // Final filtros methods
    // getImageSrc(url) {
    //   return getImageResource(url);
    // },
    timestampToTime(timestamp) {
      return moment(timestamp).format("DD-MM-YYYY");
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    async fetchData(force = false) {
      await this.$store.dispatch("getItems", {
        itemType: "articles",
        storedKey:"articles",
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          count: 16,
        },
      });
      this.isLoading = false;
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    // async handleCreateItem() {
    //   if (!this.formInput.name) {
    //     return;
    //   }
    //   try {
    //     const requestConfig = { title: {}, content: {} };
    //     const locale = this.$store.state.locale.currentLocale;
    //     requestConfig.name[locale] = this.formInput.name;
    //     requestConfig.body[locale] = this.formInput.body;
    //     await this.$store.dispatch("createItem", {
    //       item: {
    //         itemType: "articles",
    //         storedKey:"articles",
    //         requestConfig,
    //       },
    //     });
    //     this.formInput = {};
    //     this.itemImage = null;
    //   } catch {
    //     this.$toast({
    //       component: ToastificationContentVue,
    //       props: {
    //         title: this.$t("error-message.general-error"),
    //         icon: "AlertTriangleIcon",
    //         variant: "danger",
    //       },
    //     });
    //   }
    // },
    // searchItems(value) {
    //   this.searchInput = value;
    //   const searchTerm = value.toLowerCase().trim();
    //   if (!searchTerm) {
    //     return;
    //   }
    //   this.results = this.itemsData.unpaginated.filter((item) =>
    //     Object.values(item.title)[0]
    //       .toLowerCase()
    //       .includes(searchTerm)
    //   );
    // },
  },
};
</script>

<style>
.card-article-container {
  height: 285px;
}
.card-img {
  height: 200px !important;
}
.article-container img {
  max-width: 100%;
}
.link {
  color: #6e6b7b !important;
}
</style>
